.li-btn {
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  justify-content: left;
  align-items: center;
  background-color: transparent;
  color: #4b5563;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

.burgermenu-btn {
  display: flex;
  width: 100%;
  padding: 15px 0;
  outline: none;
  border: none;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}

.burgermenu-btn:hover,
.burgermenu-btn:focus-visible {
  border: none;
  outline: none;
  background-color: var(--primary-hover);
}

.btn-link {
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

/*---Sidebar---*/
.css-1wvake5.ps-collapsed {
  width: 0 !important;
  min-width: 0 !important;
}

.css-dip3t8 {
  background-color: var(--surface-ground) !important;
  border-right: var(--card-border);
}

.css-1t8x7v1 > .ps-menu-button:hover {
  background-color: var(--surface-border) !important;
}

.css-12w9als,
.ps-menu-label {
  font-size: 0.85rem !important;
  font-weight: 500;
}

.css-1wvake5 {
  width: auto !important;
  min-width: 300px !important;
  height: 100% !important;
}

.css-oypxj7 {
  height: 100%;
}

.css-1nangw0 {
  height: 100%;
}

.active-menu-item {
  background-color: var(--surface-border);
}

.default-menu-item {
  background-color: transparent;
}

/*---TabMenu---*/
.p-tabview-nav {
  flex-direction: column !important;
}

.p-tabview .p-tabview-panels {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 100% !important;
}

.p-tabview .p-tabview-nav {
  background: var(--primary-color) !important;
  border: none !important;
  border-width: 0 !important;
}

.p-tabview-nav-content {
  width: 100% !important;
}

.p-tabview .p-tabview-nav li {
  min-width: 50px;
}

.p-tabview .p-tabview-nav li :focus {
  outline: none;
}

.p-tabview-nav-container {
  background-color: var(--primary-color);
}

.p-tabview {
  height: 100%;
  display: flex;
}
.css-vj11vy.ps-menu-root {
  height: 100%;
}

.css-ewdv3l {
  height: 100%;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: var(--primary-hover) !important;
  border: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none !important;
  background: var(--primary-color) !important;
  color: var(--text) !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.version {
  display: flex;
  margin-top: 100%;
  padding-left: 20px;
  position: fixed;
  bottom: 10px;
  font-size: 0.85rem;
}
