main {
  display: block;
  width: 100%;
}

.control-section {
  display: flex;
  margin-top: 60px;
  height: 100%;
  width: 100%;
  position: fixed;
}

#wrapper {
  display: block;
  width: 100%;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.main-home-container {
  display: flex;
  flex-direction: column;
  /*overflow-y: scroll;*/
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.table-wrapper {
  display: flex;
  gap: 3rem;
}

.table-tools-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  direction: ltr;
  width: 290px;
}

.table-tools-form {
  display: flex;
  gap: 20px;
  direction: ltr;
}

.table-tools-col {
  display: flex;
  flex-direction: column;
  width: 290px;
  gap: 20px;
}

.table-tools-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.table-tools-input-wrapper p,
.table-tools-input-wrapper label {
  margin: 0;
  font-size: 0.9rem;
  white-space: nowrap;
}

.table-tools-input-wrapper p {
  font-weight: 600;
}

.table-tools-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: end;
}

.radio-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 6px 0;
}

.input-inline-wrapper {
  display: flex;
  gap: 6px;
}

.input-inline-wrapper label {
  margin: 0;
  font-size: 0.9rem;
  white-space: nowrap;
}

.data-wrapper {
  display: flex;
  width: 100%;
  gap: 3rem;
}

.p-splitter {
  border: none !important;
  color: var(--main-text) !important;
}

.rows {
  display: flex;
  height: 80vh;
  width: 100%;
}

.tree {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tree-row {
  display: flex;
  width: 65%;
}

.tree-edit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 35%;
  padding: 0 2.4rem;
}

.tree-control-row {
  display: flex;
  position: relative;
  right: 5%;
  width: fit-content;
  justify-content: flex-end;
  margin-top: -0.5rem;
}

.tree-menu {
  position: relative;
  width: 97.9%;
  height: 4rem;
  margin-top: -0.5rem;
  z-index: 1;
  background-color: var(--background-color);
}

.tree-body {
  margin-top: -4rem;
  height: 100%;
  width: 100%;
}

.tree-control-btn {
  display: flex;
  gap: 0.9rem;
  position: fixed;
  z-index: 2;
}

.toolbar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.toolbar label {
  font-size: 0.85rem;
}

.toolbar-dropdown {
  width: 18rem;
}

.toolbar-input {
  width: 5rem;
  padding: 0;
  margin: 0;
}

.creation-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  /*margin-top: 2rem;
  margin-bottom: 2rem;*/
}

.creation-block p {
  font-weight: 700;
  font-size: 0.9rem;
}

.row-block {
  display: flex;
  gap: 3vw;
  /*margin-bottom: 5rem;*/
}

.row-block-sm {
  display: flex;
  /* width: 60%;*/
  gap: 3vw;
  /*margin-bottom: 5rem;*/
}

.collumn-block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.highlight-label {
  font-weight: 700;
  font-size: 0.9rem;
}

.name-label {
  width: 16.5rem;
}

/*---table---*/
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500 !important;
  color: var(--main-text) !important;
  font-family: var(--font-family);
  font-size: 0.85rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  color: var(--main-text) !important;
  font-family: var(--font-family) !important;
  font-size: 0.85rem !important;
}

.p-datatable > .p-datatable-wrapper {
  border-radius: 4px 4px 0 0;
}

.p-paginator {
  padding: 0.5rem 1rem;
  border-radius: 0 0 4px 4px;
}

.p-inputtext {
  font-family: var(--font-family);
  font-size: 0.85rem !important;
  width: 100%;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  height: auto !important;
}

.p-icon {
  width: 0.85rem !important;
  height: 0.85rem !important;
}

.p-paginator .p-dropdown {
  height: auto;
}

/*---radio-btn---*/
.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  width: 20px;
  height: 20px;
}

/*---dropdown---*/
.p-dropdown-item {
  font-size: 0.9rem;
}

.tabpanel-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--main-text);
}

.tabpanel-input-wrapper {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
}

.tabpanel-input-wrapper label {
  font-size: 0.9rem;
}

.tabpanel-input-wrapper-p {
  line-height: normal;
  margin: 0;
  font-size: 0.9rem;
  width: 9.5rem;
}

.dropdown-custom {
  width: 130px;
}

.dropdown-custom-long {
  width: 400px;
}

.card-wrapper {
  height: 100%;
}

.card {
  direction: ltr;
}

/*---btn---*/
.p-button {
  padding: 0.65625rem 1.09375rem;
  /* background: var(--primary-color);
  border: 1px solid var(--primary-color);*/
}

.p-button.p-button-outlined:not(:disabled) {
  background: transparent;
}

.p-button.p-button-outlined:disabled {
  background: transparent;
}

.p-selectbutton .p-button.p-highlight {
  background: #ecfeff;
  color: var(--main-text);
  border-color: #8ed1d8; /* #a5f3fc */
}

.p-selectbutton .p-button {
  border: 2px solid transparent;
  border-radius: 6px;
  color: var(--main-text);
}

.custom-btn {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.custom-btn-outlined {
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/*---tree---*/
.p-tree {
  border: none;
  padding: 0;
  position: relative;
  height: 100%;
}

.p-tree .p-tree-filter-container {
  width: 30vw;
}

.p-tree-header {
  position: fixed;
  z-index: 2;
  width: 85%;
  background-color: var(--background-color);
}

.p-tree-container {
  position: relative;
  top: 4.2rem;
}

/*---panel---*/
.p-accordion .p-accordion-content {
  width: 100%;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  width: 100%;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  width: 100%;
}

/*---file-uploader---*/
.p-fileupload .p-fileupload-content {
  padding: 1.5rem 1rem;
}

.p-fileupload .p-fileupload-content {
  border: none;
}

.p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
  padding-bottom: 1rem;
}

.p-fileupload .p-fileupload-content {
  padding: 0;
}

.p-fileupload-row > div:last-child {
  text-align: left;
}

.p-fileupload-row > div {
  flex: none;
  width: 25%;
}

.p-fileupload-filename {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-button-danger {
  background-color: transparent !important;
}

/*-----toggle-btn-----*/
.p-togglebutton .p-button {
  font-size: 0.85rem;
}

.p-togglebutton.p-highlight .p-button {
  font-size: 0.85rem;
}

/*-----accordion-----*/
.p-accordion .p-accordion-content {
  color: var(--main-text);
}

.custom_content .p-accordion-content {
  background: transparent;
  border: none;
  padding: 1.25rem 0 0 !important;
}
