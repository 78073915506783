.menu {
  background: var(--background-color);
  border-bottom: var(--card-border);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.menu a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.menu_logo {
  display: flex;
  position: absolute;
  left: 40px;
  color: var(--primary-hover);
  margin: 0 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
}

.menu_logo:focus-visible {
  background-color: var(--primary-color);
  padding: 5px 20px;
  border-radius: 50px;
  color: var(--text);
  outline: none;
}
