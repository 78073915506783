@import "primereact/resources/themes/lara-light-cyan/theme.css";

body {
  margin: 0;
  height: 100vh;
  font-family: "Inter var", sans-serif;
}

:root {
  /* --primary-color: #06b6d4; */
  --primary-color: #20a7be;
  --primary-hover: #024955;
  --background-color: #ffffff;
  --main-text: #191b1d;
  --text: #ffffff;
  --table-header: #cdcecf;
  --surface-ground: #f9fafb;
  --surface-border: #dfe7ef;
  --indicator-body: #e3e3e3;
  --indicator-green: #12e063;
  --indicator-yellow: #fceb50;
  --indicator-red: #ff4f4f;
  --indicator-grey: #bec0c6;
  --card-border: 1px solid var(--surface-border);
  --font-family: "Inter var", sans-serif;
}

#root {
  height: 100%;
}

a {
  color: var(--main-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

::selection {
  background: var(--primary-hover);
  color: var(--text);
}
