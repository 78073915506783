.main_container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.indicators {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: row;
  gap: 1.3rem;
  padding-bottom: 3rem;
}

.bar {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 6;
}

.gauge_form {
  width: 33.5vw;
  margin: auto;
  margin-bottom: 2.2rem;
}

.gauge_form_p {
  font-size: 1.55vw;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 21.5%;
}

/*-----new-layout-----*/
.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._main_container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

._main_container h4 {
  margin: 0;
}

.container {
  display: grid;
  /*width: 100%;
  grid-template-columns: repeat(auto-fill, 34vw);*/
  grid-auto-flow: row dense;
  /*gap: 1rem;*/
  column-gap: 1rem;
  z-index: 2;
  margin-top: 1.5rem;
}

.monitoring_box {
  display: flex;
  gap: 2rem;
}

._bar {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 0.4rem;
  margin-top: -0.6rem;
}

._gauge_form {
  width: 28vw;
}

._gauge_form_p {
  font-size: 1.55vw;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: -0.2rem;
  margin-bottom: 0.8rem;
}

.custom_btn .p-button {
  padding: 0;
  background: transparent;
  border: 2px solid transparent;
}

.custom .p-button-group .p-button:first-of-type {
  border-radius: 0;
}
