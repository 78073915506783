.indicator_color {
  display: block;
  border-radius: 50%;
  z-index: 3;
  position: relative;
  top: 0;
  left: 0.4vh;
}

.blink_bg {
  display: block;
  border-radius: 50%;
  z-index: 3;
  position: relative;
  top: 0;
  left: 0.4vh;
  animation: blinkingBackground 1s infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: var(--indicator-grey);
  }
  100% {
    background-color: var(--indicator-red);
  }
}

.indicators {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 35vw;
  height: 100vh;
  padding: 1rem 1rem 4.5rem;
  border-left: var(--card-border);
  overflow-y: scroll;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.text h5,
.text p {
  margin: 0;
}

.text p {
  font-size: 0.85rem;
}

.message {
  color: var(--main-text);
  font-weight: 700;
  justify-content: start;
  align-items: center;
  padding-left: 1.2rem;
}

.info_message,
.p-inline-message.p-inline-message-info {
  color: var(--main-text);
  font-weight: 700;
  justify-content: start;
  align-items: center;
  background: #f2f3f5;
  padding-left: 1.2rem;
}

.custom_header .p-accordion .p-accordion-header .p-accordion-header-link {
  border: none;
  background: transparent;
  padding: 0;
  width: 100%;
}

.custom_header a,
.p-accordion-header-link a {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
